const localize = () => {
  const locale =
    localStorage.getItem("gameLocale", window.gameLocale) ||
    window.gameLocale ||
    navigator.language;
  if (locale !== "en" && !locale.includes("en-")) {
    // TODO: the game is already in english! What about other english locales!!
    if (locale.includes("es-") || locale === "es") {
      window.gameLocale = "es";
    } else if (locale.startsWith("zh") || locale === "zh") {
      window.gameLocale = "zho";
    }
  } else {
    window.gameLocale = "en";
  }
  if (locales[window.gameLocale]) {
    // Does the locale exist?
    const localizableElems = document.getElementsByClassName("localized");
    for (let i = 0; i < localizableElems.length; i += 1) {
      localizableElems[i].innerHTML =
        locales[window.gameLocale][localizableElems[i].dataset.localeItem];
    }
    // localize page title
    document.title = `Zoomtastic | ${locales[window.gameLocale]["GAME_TITLE"]}`;
  }
};

const localizeItem = (itemName, ...params) => {
  if (params && params.length > 0) {
    return locales[window.gameLocale][itemName](...params);
  } else {
    return locales[window.gameLocale][itemName];
  }
};

const redirectToLocalizedPage = () => {
  const locale =
    localStorage.getItem("gameLocale", window.gameLocale) ||
    window.gameLocale ||
    navigator.language;
  if (
    !location.pathname.includes("/es") &&
    (locale.includes("es-") || locale === "es")
  ) {
    location.href = "/es";
  } else if (
    (!location.pathname.includes("/zh") && locale.startsWith("zh")) ||
    locale === "zh"
  ) {
    location.href = "/zho";
  }
};
